import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
// import { Deeplinks } from '@awesome-cordova-plugins/deeplinks';


import AuthMiddleware from '../middleware/auth';
import TrackingMiddleware from '../middleware/tracking';

import MainPage from '../views/MainPage.vue';

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   redirect: '/'
  // },

  // ==================================== Login / Auth ====================================
  {
    path: '/',
    component: () => import('@/views/Page/LaunchPage.vue'),
  },
  {
    path: '/login',
    component: () => import('@/views/Page/Auth/LoginPage.vue')
  },
  {
    path: '/oauth/google',
    component: () => import('@/views/Auth/GoogleAuth.vue')
  },
  {
    path: '/oauth/apple',
    component: () => import('@/views/Auth/AppleAuth.vue')
  },
  {
    path: '/signin-oidc',
    component: () => import('@/views/Auth/TriqAuth.vue')
  },



  // ==================================== Onboarding Process ====================================
  {
    path: '/onboarding/email',
    component: () => import('@/views/Page/Onboarding/EmailPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/connect',
    component: () => import('@/views/Page/Onboarding/Connect/ConnectPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/connect/abort',
    component: () => import('@/views/Page/Onboarding/Connect/ConnectAbortPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/connect/abort/complete',
    component: () => import('@/views/Page/Onboarding/Connect/ConnectAbortCompletePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/connect/garmin',
    component: () => import('@/views/Page/Onboarding/Connect/ConnectGarminPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/connect/garmin/success',
    component: () => import('@/views/Page/Onboarding/Connect/ConnectGarminSuccessPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/connect/garmin/restrictions',
    component: () => import('@/views/Page/Onboarding/Connect/ConnectGarminRestrictionsPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/about-you',
    component: () => import('@/views/Page/Onboarding/AboutYouPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/profile',
    component: () => import('@/views/Page/Onboarding/ProfilePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/intention',
    component: () => import('@/views/Page/Onboarding/IntentionPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/goal/arace',
    component: () => import('@/views/Page/Onboarding/Goal/ARacePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/racing-history',
    component: () => import('@/views/Page/Onboarding/RacingHistoryPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/training-constraints',
    component: () => import('@/views/Page/Onboarding/TrainingConstraintsPage.vue'),
    meta: { requiresAuth: true }
  },


  {
    path: '/onboarding/swim-insights',
    component: () => import('@/views/Page/Onboarding/SwimInsightsPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/endurance-experience/swimming/volume',
    component: () => import('@/views/Page/Onboarding/EnduranceExperience/SwimmingVolumePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/endurance-experience/swimming/volume/weeks',
    component: () => import('@/views/Page/Onboarding/EnduranceExperience/SwimmingVolumeWeeksPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/endurance-experience/swimming/threshold',
    component: () => import('@/views/Page/Onboarding/EnduranceExperience/SwimmingThresholdPage.vue'),
    meta: { requiresAuth: true }
  },

  {
    path: '/onboarding/bike-insights',
    component: () => import('@/views/Page/Onboarding/BikeInsightsPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/endurance-experience/cycling/volume',
    component: () => import('@/views/Page/Onboarding/EnduranceExperience/CyclingVolumePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/endurance-experience/cycling/volume/weeks',
    component: () => import('@/views/Page/Onboarding/EnduranceExperience/CyclingVolumeWeeksPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/endurance-experience/cycling/threshold',
    component: () => import('@/views/Page/Onboarding/EnduranceExperience/CyclingThresholdPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/endurance-experience/cycling/threshold/power',
    component: () => import('@/views/Page/Onboarding/EnduranceExperience/CyclingThresholdPowerPage.vue'),
    meta: { requiresAuth: true }
  },

  {
    path: '/onboarding/run-insights',
    component: () => import('@/views/Page/Onboarding/RunInsightsPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/endurance-experience/running/volume',
    component: () => import('@/views/Page/Onboarding/EnduranceExperience/RunningVolumePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/endurance-experience/running/volume/weeks',
    component: () => import('@/views/Page/Onboarding/EnduranceExperience/RunningVolumeWeeksPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/endurance-experience/running/threshold',
    component: () => import('@/views/Page/Onboarding/EnduranceExperience/RunningThresholdPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/endurance-experience/running/threshold/pace',
    component: () => import('@/views/Page/Onboarding/EnduranceExperience/RunningThresholdPacePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/endurance-experience/maf',
    component: () => import('@/views/Page/Onboarding/EnduranceExperience/MafPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/endurance-experience/upper-pace-threshold-calculator',
    component: () => import('@/views/Page/Onboarding/EnduranceExperience/UpperPaceThresholdCalculatorPage.vue'),
    meta: { requiresAuth: true }
  },

  {
    path: '/onboarding/endurance-experience/other',
    component: () => import('@/views/Page/Onboarding/EnduranceExperience/OtherSportsPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/injury',
    component: () => import('@/views/Page/Onboarding/InjuryPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/training-volume',
    component: () => import('@/views/Page/Onboarding/TrainingVolumePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/availability',
    component: () => import('@/views/Page/Onboarding/AvailabilityPage.vue'),
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/onboarding/notifications',
  //   component: () => import('@/views/Page/Onboarding/NotificationsPage.vue'),
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/onboarding/feasibility-check',
    component: () => import('@/views/Page/Onboarding/FeasibilityCheckPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/complete',
    component: () => import('@/views/Page/Onboarding/CompletePage.vue'),
    meta: { requiresAuth: true }
  },



  // ==================================== Daily Checkin ====================================
  {
    path: '/checkin',
    component: () => import('@/views/Page/Checkin/IntroPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/checkin/device',
    component: () => import('@/views/Page/Checkin/HrvDeviceSelectPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/checkin/hrv-measurement',
    component: () => import('@/views/Page/Checkin/HrvMeasurementPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/checkin/hrv-measurement/success',
    component: () => import('@/views/Page/Checkin/HrvMeasurementSuccessPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/checkin/hrv-measurement/failed',
    component: () => import('@/views/Page/Checkin/HrvMeasurementFailedPage.vue'),
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/checkin/orthopedic-fitness',
  //   component: () => import('@/views/Page/Checkin/OrthopedicFitnessPage.vue'),
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/checkin/questionaire',
    component: () => import('@/views/Page/Checkin/QuestionairePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/checkin/result',
    component: () => import('@/views/Page/Checkin/ResultPage.vue'),
    meta: { requiresAuth: true }
  },

  {
    path: '/checkin/muscle-fatigue',
    component: () => import('@/views/Page/Checkin/MuscleFatiguePage.vue'),
    meta: { requiresAuth: true }
  },



  // ==================================== Main App ====================================
  {
    path: '/app/',
    component: MainPage,
    children: [
      {
        path: '',
        redirect: '/app/dashboard'
      },

      // ==================================== Now/Today/Plan ====================================
      {
        path: 'dashboard',
        // component: () => import('@/views/Page/DemoPage.vue'),
        component: () => import('@/views/Page/Plan/IndexPage.vue'),
        meta: { requiresAuth: true }
      },

      

      // ==================================== Forecast ====================================
      {
        path: 'forecast',
        component: () => import('@/views/Page/Forecast/IndexPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'forecast/training-phases',
        component: () => import('@/views/Page/Forecast/TrainingPhasesPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'forecast/edit',
        component: () => import('@/views/Page/Forecast/EditPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'forecast/recalculate',
        component: () => import('@/views/Page/Forecast/RecalculatePage.vue'),
        meta: { requiresAuth: true }
      },

      // ==================================== Roadmap ====================================
      {
        path: 'roadmap',
        // component: () => import('@/views/Page/DemoPage.vue'),
        component: () => import('@/views/Page/Roadmap/IndexPage.vue'),
        meta: { requiresAuth: true }
      },

      // ==================================== History ====================================
      {
        path: 'history',
        component: () => import('@/views/Page/History/IndexPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'history/duration',
        component: () => import('@/views/Page/History/DurationPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'history/distance',
        component: () => import('@/views/Page/History/DistancePage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'history/performance-development',
        component: () => import('@/views/Page/History/PerformanceDevelopmentPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'history/completion-rate',
        component: () => import('@/views/Page/History/CompletionRatePage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'history/workouts',
        component: () => import('@/views/Page/History/WorkoutsPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'history/cardio',
        component: () => import('@/views/Page/History/CardioComingSoonPage.vue'),
        meta: { requiresAuth: true }
      }
    ]
  },


  // ==================================== Workouts ====================================
  {
    path: '/app/workout/completed/:id',
    component: () => import('@/views/Page/Workout/PostWorkoutPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/workout/planned/:id',
    component: () => import('@/views/Page/Workout/PreWorkoutPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/workout/strength/:id',
    component: () => import('@/views/Page/Workout/StrengthWorkoutPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/workout/multisport/:id',
    component: () => import('@/views/Page/Workout/MultisportWorkoutPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/workout/performance-test-info/swimming',
    component: () => import('@/views/Page/Workout/PerformanceTest/SwimmingInfoPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/workout/performance-test-info/cycling',
    component: () => import('@/views/Page/Workout/PerformanceTest/CyclingInfoPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/workout/performance-test-info/running',
    component: () => import('@/views/Page/Workout/PerformanceTest/RunningInfoPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/workout/performance-test/:id/threshold',
    component: () => import('@/views/Page/Workout/PerformanceTest/UpdateThresholdPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/workout/performance-test/:id/pre',
    component: () => import('@/views/Page/Workout/PerformanceTest/PreWorkoutPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/workout/performance-test/:id/post',
    component: () => import('@/views/Page/Workout/PerformanceTest/PostWorkoutPage.vue'),
    meta: { requiresAuth: true }
  },

  // ==================================== Notifications ====================================
  {
    path: '/notifications',
    component: () => import('@/views/Page/Notification/IndexPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/notification/:id',
    component: () => import('@/views/Page/Notification/DetailPage.vue'),
    meta: { requiresAuth: true }
  },

  // ==================================== Profile & Settings ====================================
  {
    path: '/app/settings',
    component: () => import('@/views/Page/Settings/IndexPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/profile/edit/profile-picture',
    component: () => import('@/views/Page/Settings/Profile/EditProfilePicturePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/profile/edit/password',
    component: () => import('@/views/Page/Settings/Profile/ChangePasswordPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/profile/edit/email',
    component: () => import('@/views/Page/Settings/Profile/EditEmailPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/profile/edit/nickname',
    component: () => import('@/views/Page/Settings/Profile/EditNickNamePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/profile/edit/firstname',
    component: () => import('@/views/Page/Settings/Profile/EditFirstNamePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/profile/edit/lastname',
    component: () => import('@/views/Page/Settings/Profile/EditLastNamePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/profile/edit/height',
    component: () => import('@/views/Page/Settings/Profile/EditHeightPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/profile/edit/weight',
    component: () => import('@/views/Page/Settings/Profile/EditWeightPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/profile/edit/date-of-birth',
    component: () => import('@/views/Page/Settings/Profile/EditDateOfBirthPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/profile/edit/gender',
    component: () => import('@/views/Page/Settings/Profile/EditGenderPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/goal-and-availability',
    component: () => import('@/views/Page/Settings/GoalAndAvailability/IndexPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/goal-and-availability/race-goal',
    component: () => import('@/views/Page/Settings/GoalAndAvailability/RaceGoalPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/goal-and-availability/training-volume',
    component: () => import('@/views/Page/Settings/GoalAndAvailability/TrainingVolumePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/goal-and-availability/availability',
    component: () => import('@/views/Page/Settings/GoalAndAvailability/AvailabilityPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/training-zones-and-thresholds',
    component: () => import('@/views/Page/Settings/ZonesAndThresholdPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/training-volume-capability',
    component: () => import('@/views/Page/Settings/TrainingVolumeCapability/IndexPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/training-volume-capability/history',
    component: () => import('@/views/Page/Settings/TrainingVolumeCapability/ChangeHistoryPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/long-session-capability',
    component: () => import('@/views/Page/Settings/LongSessionCapability/IndexPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/long-session-capability/history',
    component: () => import('@/views/Page/Settings/LongSessionCapability/ChangeHistoryPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/injury-tendencies',
    component: () => import('@/views/Page/Settings/InjuryTendenciesPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/performance-tests',
    component: () => import('@/views/Page/Settings/PerformanceTestsPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/communication',
    component: () => import('@/views/Page/Settings/CommunicationPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/third-party-connections',
    component: () => import('@/views/Page/Settings/ThirdPartyConnectionPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/third-party-connections/connect/garmin',
    component: () => import('@/views/Page/Settings/Connect/ConnectGarminPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/units-of-measurement',
    component: () => import('@/views/Page/Settings/UnitsOfMeasurementPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/units-of-measurement/edit/unit-system',
    component: () => import('@/views/Page/Settings/UnitsOfMeasurement/EditUnitSystemPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/units-of-measurement/edit/intensity-description',
    component: () => import('@/views/Page/Settings/UnitsOfMeasurement/EditIntensityDescriptionPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/units-of-measurement/edit/pool-length',
    component: () => import('@/views/Page/Settings/UnitsOfMeasurement/EditPoolLengthPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/units-of-measurement/edit/cycling-endurance',
    component: () => import('@/views/Page/Settings/UnitsOfMeasurement/EditCyclingEnduranceMeasurementTypePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/units-of-measurement/edit/cycling-interval',
    component: () => import('@/views/Page/Settings/UnitsOfMeasurement/EditCyclingIntervalMeasurementTypePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/units-of-measurement/edit/cycling-indoor',
    component: () => import('@/views/Page/Settings/UnitsOfMeasurement/EditCyclingIndoorMeasurementTypePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/units-of-measurement/edit/running-endurance',
    component: () => import('@/views/Page/Settings/UnitsOfMeasurement/EditRunningEnduranceMeasurementTypePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/units-of-measurement/edit/running-interval',
    component: () => import('@/views/Page/Settings/UnitsOfMeasurement/EditRunningIntervalMeasurementTypePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/settings/support-and-feedback',
    component: () => import('@/views/Page/Settings/SupportAndFeedbackPage.vue'),
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/app/settings/faq',
  //   component: () => import('@/views/Page/Settings/FaqPage.vue'),
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/app/settings/glossary',
  //   component: () => import('@/views/Page/Settings/GlossaryPage.vue'),
  //   meta: { requiresAuth: true }
  // },

  // Developer settings
  {
    path: '/app/settings/developer',
    component: () => import('@/views/Page/DemoPage.vue'),
    meta: { requiresAuth: true }
  },

  {
    path: '/app/support',
    component: () => import('@/views/Page/Support/SupportPage.vue')
  },

  {
    path: '/app/sean-ellis-test',
    component: () => import('@/views/Page/SeanEllis/IndexPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/app/sean-ellis-test/feedback',
    component: () => import('@/views/Page/SeanEllis/FeedbackPage.vue'),
    meta: { requiresAuth: true }
  },


  {
    path: "/terms",
    component: () => import('@/views/Page/Legal/TermsPage.vue')
  },
  {
    path: "/privacy",
    component: () => import('@/views/Page/Legal/PrivacyPage.vue')
  },

  // {
  //   path: '/app/',
  //   component: TabsPage,
  //   children: [
  //     {
  //       path: '',
  //       redirect: '/app/dashboard'
  //     },
  //     {
  //       path: 'dashboard',
  //       // component: () => import('@/views/DashboardPage.vue')
  //       component: () => import('@/views/Page/DemoPage.vue')
  //     },
  //     {
  //       path: 'calendar',
  //       component: () => import('@/views/CalendarPage.vue')
  //     },
  //     {
  //       path: 'roadmap',
  //       component: () => import('@/views/RoadmapPage.vue')
  //     },
  //     {
  //       path: 'profile',
  //       component: () => import('@/views/ProfilePage.vue')
  //     },
      {
        path: '/inapp',
        component: ()  => import('@/views/InAppPurchaseDemoPage.vue')
      }
  //   ]
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


router.beforeEach(async (to, from) => {
  performance.mark('app.routing.middleware.start');

  const middlewares = [AuthMiddleware];

  for (let i = 0; i < middlewares.length; i++) {
    const result = await middlewares[i].before(to, from);
    if (result != null) {
      performance.mark('app.routing.middleware.end');

      return result;
    }
  }

  performance.mark('app.routing.middleware.end');

  performance.mark('app.routing.page.start', {
    detail: { path: to.path }
  });
});

router.afterEach(async (to, from) => {
  const middlewares = [TrackingMiddleware];

  for (let i = 0; i < middlewares.length; i++) {
    const result = await middlewares[i].before(to, from);
    if (result != null) {
      return result;
    }
  }

  performance.mark('app.routing.page.end', {
    detail: { path: to.path }
  });
});


// Deeplinks.route({
//   '/test': () => import('@/views/ProfilePage.vue')
// }).subscribe(match => {
//   console.log('Deeplink', match);
// }, nomatch => {
//   console.log('Deeplink nomatch', nomatch);
// });

export default router
