import axios from 'axios';

import { TRIQ_API_URL, TRIQ_IDENTITY_PROVIDER_URL } from '../config';

const API_ENDPOINT_IDENTITY = '/api/v1/app/identity';
const API_ENDPOINT_EVENT = '/api/v1/app/event';

const API_ENDPOINT_INTEGRATION_GARMIN_AUTH_REQUEST_TOKEN = '/api/v1/integration/garmin/auth/request-token/:athleteId';
const API_ENDPOINT_INTEGRATION_GARMIN_AUTH_IS_CONNECTED = '/api/v1/integration/garmin/auth/is-connected/:athleteId';
const API_ENDPOINT_INTEGRATION_GARMIN_AUTH_HAS_PERMISSION = '/api/v1/integration/garmin/auth/has-required-permissions/:athleteId';
const API_ENDPOINT_INTEGRATION_GARMIN_AUTH_DISONNECT = '/api/v1/integration/garmin/auth/disconnect/:athleteId';

const API_ENDPOINT_MEASUREMENTS_HEART_RATE_CAMERA_ANALYSIS = '/api/v1/app/measurements/heart-rate/camera/analysis';

const API_ENDPOINT_THRESHOLDS = '/api/v1/thresholds';
const API_ENDPOINT_THRESHOLD_SETTINGS = '/api/v1/thresholds/settings';
const API_ENDPOINT_THRESHOLDS_DEFAULT = '/api/v1/thresholds/default';
const API_ENDPOINT_THRESHOLDS_SWIMMING = '/api/v1/thresholds/swimming';
const API_ENDPOINT_THRESHOLDS_SWIMMING_DEFAULT = '/api/v1/thresholds/swimming/default';
const API_ENDPOINT_THRESHOLDS_RUNNING = '/api/v1/thresholds/running';
const API_ENDPOINT_THRESHOLDS_RUNNING_HR_DEFAULT = '/api/v1/thresholds/running/hr/default';
const API_ENDPOINT_THRESHOLDS_CYCLING = '/api/v1/thresholds/cycling';
const API_ENDPOINT_THRESHOLDS_CYCLING_HR_DEFAULT = '/api/v1/thresholds/cycling/hr/default';
const API_ENDPOINT_THRESHOLDS_CYCLING_POWER_DEFAULT = '/api/v1/thresholds/cycling/power/default';

const API_ENDPOINT_MAF = '/api/v1/thresholds/maffetone';

const API_ENDPOINT_THRESHOLDS_WORKOUT = '/api/v1/app/workout/chart';

const API_ENDPOINT_DEVICE_REGISTER = '/api/v1/app/device/register';
const API_ENDPOINT_DEVICE_UNREGISTER = '/api/v1/app/device/unregister';
const API_ENDPOINT_DEVICE_PERMISSIONS = '/api/v1/app/device/permissions';

const API_ENDPOINT_EQUIPMENT_OPTIONS = '/api/v1/app/equipment/options';
const API_ENDPOINT_EQUIPMENT_VALIDATE = '/api/v1/app/equipment/validate';

const API_ENDPOINT_PROFILE_PICTURE_UPLOAD = '/api/v1/app/upload-profile-picture';
const API_ENDPOINT_PROFILE_PICTURE_DELETE = '/api/v1/app/delete-profile-picture';

const API_ENDPOINT_SUPPORT_TICKET = '/api/v1/support/ticket';

const API_IDENTITY_PROVIDER_CHANGE_PASSWORD = '/api/v1/User/change-password';
const API_IDENTITY_PROVIDER_RESET_ACCOUNT = '/api/v1/User/resetAccount';

const API_ENDPOINT_READINESS = '/api/v1/app/readiness';

const API_ENDPOINT_AVAILABILITY_MIN_LONG_SESSION_DURATIONS = '/api/v1/app/availability/min-long-session-durations';

const API_ENDPOINT_DASHBOARD_STATUS = '/api/v1/app/dashboard-status';

const API_ENDPOINT_RESET_THRESHOLDS = '/api/v1/test/reset-thresholds';
const API_ENDPOINT_RESET_ATHLETE = '/api/v1/app/reset-athlete';

const API_ENDPOINT_SETTINGS_SWIM_PARAMETERS = '/api/v1/app/settings/swim-parameters'; 
const API_ENDPOINT_SETTINGS_TRAINING_PARAMETERS_HISTORY = '/api/v1/app/training-parameters/history';
const API_ENDPOINT_SETTINGS_TRAINING_PARAMETERS_TOTAL_UPDATE = '/api/v1/app/training-parameters/total';
const API_ENDPOINT_SETTINGS_TRAINING_PARAMETERS_LONG_UPDATE = '/api/v1/app/training-parameters/long';

export default {
    namespaced: true,

    state: {
        identity: null,

        timeRegex: /^[0-2]?\d:[0-5]\d$/,

        // swimming
        threshold_swimming_css_min: 54,   // 0.9 min/100m
        threshold_swimming_css_max: 270,  // 4.5 min/100m

        // running
        threshold_running_pace_min: 150,   // 2.5 min/km
        threshold_running_pace_max: 1200, // 20  min/km
        threshold_running_pace_min_imperial: 241.4016, 
        threshold_running_pace_max_imperial: 1931.4,
    },

    mutations: {
        setIdentity(state, identity) {
            state.identity = identity;
        }
    },

    actions: {
        fetchIdentity({ dispatch, commit }) {
            return dispatch('getIdentity').then(response => {
                commit('setIdentity', response.data);

                return response.data;
            });
        },

        getIdentity({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_IDENTITY, config);
            }, {root: true});
        },

        storeEvent({ dispatch }, { event, action = null }) {
            return dispatch('auth/request', {action: action, callback: config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_EVENT, event, config);
            }}, {root: true});
        },

        integrationGarminAuthRequestToken({ state, dispatch }, { redirectTo }) {
            return dispatch('auth/request', config => {
                // const returnUrl = FRONTEND_BASE_URL + '/onboarding/connect/garmin';

                if (redirectTo) {
                    config.params = {
                        redirectTo: redirectTo
                    };
                }

                return axios.get(TRIQ_API_URL + API_ENDPOINT_INTEGRATION_GARMIN_AUTH_REQUEST_TOKEN.replace(':athleteId', state.identity.athleteId), config);
            }, {root: true});
        },

        integrationGarminAuthIsConnected({ state, dispatch }) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_INTEGRATION_GARMIN_AUTH_IS_CONNECTED.replace(':athleteId', state.identity.athleteId), config);
            }, {root: true});
        },

        integrationGarminAuthHasPermission({ state, dispatch }) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_INTEGRATION_GARMIN_AUTH_HAS_PERMISSION.replace(':athleteId', state.identity.athleteId), config)
            }, {root: true});
        },

        integrationGarminAuthDisconnect({ state, dispatch }) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_INTEGRATION_GARMIN_AUTH_DISONNECT.replace(':athleteId', state.identity.athleteId), {}, config);
            }, {root: true});
        },

        storeHrvMeasurement({ dispatch }, { hrvData }) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_MEASUREMENTS_HEART_RATE_CAMERA_ANALYSIS, hrvData, config);
            }, {root: true});
        },

        getThresholds({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_THRESHOLDS, config);
            }, { root: true });
        },

        getThresholdSettings({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_THRESHOLD_SETTINGS, config);
            }, { root: true });
        },

        updateThresholds({ dispatch }, { thresholds, action = null }) {
            return dispatch('auth/request', {action: action, callback: config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_THRESHOLDS, thresholds, config);
            }}, { root: true });
        },

        registerDevice({ dispatch }, device) {
            return dispatch('auth/request', config => {
                console.log('Registering device', device);

                if (!device.deviceName) {
                    device.deviceName = 'UNKNOWN';
                }

                if (!device.deviceManufacturer) {
                    device.deviceManufacturer = 'UNKNOWN';
                }

                if (!device.deviceOperatingSystem) {
                    device.deviceOperatingSystem = 'UNKNOWN';
                }

                if (!device.expiresAt) {
                    const expiration = new Date();
                    expiration.setDate(expiration.getDate() + 30);
                    device.expiresAt = expiration.toISOString();
                }

                return axios.put(TRIQ_API_URL + API_ENDPOINT_DEVICE_REGISTER, device, config);
            }, {root: true});
        },

        unregisterDevice({ dispatch }, deviceId) {
            return dispatch('auth/request', config => {
                console.debug('Unregistering device', deviceId);
                return axios.put(TRIQ_API_URL + API_ENDPOINT_DEVICE_UNREGISTER + '?deviceId=' + encodeURIComponent(deviceId), {}, config);
            }, { root: true });
        },

        updateDevicePermissions({ dispatch }, payload) {
            return dispatch('auth/request', config => {
                console.debug('Update device push notification settings', payload.deviceId);
                return axios.post(TRIQ_API_URL + API_ENDPOINT_DEVICE_PERMISSIONS, payload, config);
            }, { root: true });
        },

        getEquipmentOptions({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_EQUIPMENT_OPTIONS, config);
            }, { root: true });
        },

        validateEquipment({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_EQUIPMENT_VALIDATE, config);
            }, { root: true });
        },

        uploadProfilePicture({ dispatch }, { profilePicture }) {
            return dispatch('auth/request', config => {
                config.headers['Content-Type'] = 'multipart/form-data';

                const form = new FormData();
                form.append('file', profilePicture, 'avatar.jpg'); // TODO: new Blob?

                return axios.post(TRIQ_API_URL + API_ENDPOINT_PROFILE_PICTURE_UPLOAD, form, config);
            }, { root: true });
        },

        deleteProfilePicture({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_PROFILE_PICTURE_DELETE, {}, config);
            }, { root: true });
        },

        createSupportTicket({ dispatch }, data) {
            return dispatch('auth/request', {action: 'support_ticket.create', callback: config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_SUPPORT_TICKET, data, config);
            }}, { root: true });
        },

        changePassword({ dispatch }, data) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_IDENTITY_PROVIDER_URL + API_IDENTITY_PROVIDER_CHANGE_PASSWORD, data, config);
            }, { root: true }) ;
        },

        updateSwimmingThresholds({ dispatch }, thresholds) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_THRESHOLDS_SWIMMING, thresholds, config);
            }, { root: true });
        },

        updateSwimmingThresholdsDefault({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_THRESHOLDS_SWIMMING_DEFAULT, {}, config);
            }, { root: true });
        },

        updateRunningThresholds({ dispatch }, thresholds) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_THRESHOLDS_RUNNING, thresholds, config);
            }, { root: true });
        },

        updateRunningThresholdHrDefault({ dispatch }, option) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_THRESHOLDS_RUNNING_HR_DEFAULT, {option: option}, config);
            }, { root: true });
        },

        updateCyclingThresholds({ dispatch }, thresholds) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_THRESHOLDS_CYCLING, thresholds, config);
            }, { root: true });
        },

        updateCyclingThresholdHrDefault({ dispatch }, option) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_THRESHOLDS_CYCLING_HR_DEFAULT, { option: option }, config);
            }, { root: true });
        },

        updateCyclingThresholdPowerDefault({ dispatch }, option) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_THRESHOLDS_CYCLING_POWER_DEFAULT, { option: option }, config);
            }, { root: true });
        },

        updateThresholdsDefault({ dispatch }, payload) {
            return dispatch('auth/request', config => {
                let queryParams = '?';
                for (const key in payload) {
                    if (Array.isArray(payload[key])) {
                        for (const value of payload[key]) {
                            queryParams += key + '=' + value + '&';
                        }
                    } else if (payload[key] != null) {
                        queryParams += key + '=' + payload[key] + '&';
                    }
                }

                return axios.post(TRIQ_API_URL + API_ENDPOINT_THRESHOLDS_DEFAULT + queryParams, {}, config);
            }, { root: true });
        },

        getWorkoutThresholds({ dispatch }, workoutId) {
            return dispatch('auth/request', config => {
                if (!config.params) {
                    config.params = {};
                }
                config.params.workoutId = workoutId;

                return axios.get(TRIQ_API_URL + API_ENDPOINT_THRESHOLDS_WORKOUT, config);
            }, { root: true });
        },

        getRecovery({ dispatch }, params) {
            return dispatch('auth/request', config => {
                config.params = Object.assign(config.params || {}, params);
                return axios.get(TRIQ_API_URL + API_ENDPOINT_READINESS, config);
            }, { root: true });
        },

        getAvailabilityMinLongSessionDurations({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_AVAILABILITY_MIN_LONG_SESSION_DURATIONS, config);
            }, { root: true });
        },

        getDashboardStatus({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_DASHBOARD_STATUS, config);
            }, { root: true });
        },

        resetThresholds({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_RESET_THRESHOLDS, {}, config);
            }, { root: true });
        },

        resetAthlete({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_RESET_ATHLETE, {}, config);
            }, { root: true });
        },

        resetAccount({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_IDENTITY_PROVIDER_URL + API_IDENTITY_PROVIDER_RESET_ACCOUNT, {}, config);
            }, { root: true });
        },

        getSwimParameters({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_SETTINGS_SWIM_PARAMETERS, config);
            }, { root: true });
        },

        getTrainingParametersHistory({ dispatch }, params) {
            return dispatch('auth/request', config => {
                config.params = Object.assign(config.params || {}, params);
                return axios.get(TRIQ_API_URL + API_ENDPOINT_SETTINGS_TRAINING_PARAMETERS_HISTORY, config);
            }, { root: true });
        },

        getMaffetoneThreshold({ dispatch }, answers) {
            return dispatch('auth/request', config => {
                // append answers as query params
                let queryParams = '?';
                for (const key in answers) {
                    queryParams += 'answers=' + answers[key] + '&';
                }
                
                return axios.get(TRIQ_API_URL + API_ENDPOINT_MAF + queryParams, config);
            }, { root: true });
        },

        updateTrainingParametersTotal({ dispatch }, data) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_SETTINGS_TRAINING_PARAMETERS_TOTAL_UPDATE, data, config);
            }, { root: true });
        },

        updateTrainingParametersLong({ dispatch }, data) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_SETTINGS_TRAINING_PARAMETERS_LONG_UPDATE, data, config);
            }, { root: true });
        }
    }
};
